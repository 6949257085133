import { Container, Row, Col, Table, Modal, Form, Button } from "react-bootstrap";
import { apiService } from "../../service/api.service";
import { useEffect, useState } from "react";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";

const UpdateBenjaminNftModal = ({ show, handleClose, nft, refreshData, loading, setLoading }) => {

  const [benjaminNftFormData, setBenjaminNftFormData] = useState({
    title: nft?.title || "",
    link: nft?.link || "",
  });
  // const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setBenjaminNftFormData({
      title: nft?.title || "",
      link: nft?.link || "",
    })
    setErrors({});
  }, [nft, show])



  const validateInputs = (benjaminNftData = benjaminNftFormData) => {
    let isValid = true;
    let newErrors = {};

    if (!benjaminNftData.title.trim()) {
      newErrors.title = "Title is required";
      isValid = false;
    }

    const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[\w-]{2,4}(:\d+)?(\/[\w\-./?%&=]*)?$/;
    if (!benjaminNftData.link.trim()) {
      newErrors.link = "Link is required";
      isValid = false;
    } else if (!urlPattern.test(benjaminNftData.link)) {
      newErrors.link = "Enter a valid URL";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdate = async () => {
    if (!validateInputs()) return;

    setLoading(true);
    try {
      await apiService.updateBenjaminNft(nft._id, benjaminNftFormData);
      refreshData();
      handleClose();
    } catch (error) {
      setLoading(false);
      console.error("Error updating NFT:", error);
    } 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBenjaminNftFormData((prev) => ({ ...prev, [name]: value }));
    validateInputs({ ...benjaminNftFormData, [name]: value })
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Benjamin NFT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={benjaminNftFormData.title}
              onChange={handleChange}
              isInvalid={!!errors.title}
              readOnly
            />
            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLink" className="mt-3">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              name="link"
              value={benjaminNftFormData.link}
              onChange={handleChange}
              isInvalid={!!errors.link}
            />
            <Form.Control.Feedback type="invalid">{errors.link}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleUpdate} disabled={loading}>
          {loading ? "Updating..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


const BenjaminNft
  = () => {
    const [benjaminNfts, setBenjaminNfts] = useState([]);
    const [selectedNft, setSelectedNft] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      fetchBenjaminNftData();
    }, []);

    async function fetchBenjaminNftData() {
      const data = await apiService.getBenjaminNft();
      setBenjaminNfts(data.data);
      setLoading(false);
    }

    async function updatedBenjaminNftData() {
      fetchBenjaminNftData();
      swal({ icon: "success", text: "Benjamin NFT link has been successfully updated!"});
    }

    return (
      <>
        {loading ? <ThreeDotSpinner /> : ""}
        <div className="dashboard-main-area">
          <Container fluid>
            <div className="dashboard-area-heading management-heading">
              <Row className="row justify-content-center align-items-center mb-3">
                <Col md={12} lg={11}>
                  <div className="heading-top-area">
                    <div className="d-flex ">
                      <h2 className="align-self-center m-0">Update Benjamin NFTs</h2>
                    </div>
                  </div>
                </Col>

              </Row>
              <Row className="pb-4 justify-content-center">
                <Col md={12} lg={11}>
                  <div className="availabilit-section">
                    <Table responsive="sm" className="UserListTable">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Title</th>
                          <th>Links</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {benjaminNfts.map((nft, index) => (
                          <tr key={nft._id}>
                            <td>{index + 1}</td>
                            <td>{nft.title}</td>
                            <td>
                              <a href={nft.link} target="_blank" rel="noopener noreferrer">
                                {nft.link}
                              </a>
                            </td>
                            <td>
                              <div className="edit-pencil"
                                onClick={() => {
                                  setSelectedNft(nft);
                                  setModalShow(true);
                                }}
                              >
                                <i className="fa fa-pencil" style={{ cursor: "pointer" }}></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {selectedNft && (
          <UpdateBenjaminNftModal
            show={modalShow}
            handleClose={() => setModalShow(false)}
            nft={selectedNft}
            refreshData={updatedBenjaminNftData}
            loading={loading} setLoading={setLoading}
          />
        )}
      </>
    );
  };

export default BenjaminNft;
