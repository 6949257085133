import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";
import { getItem } from "./util/localStorage";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
import "./App.css";
import "./assets/css/style.css";
import { MyContext } from "./config/config";

import { UsersList } from "./components/users/UsersList";
import { PredictionManagement } from "./components/predictionManagement/prediction";
import { Profile } from "./components/profile/AdminProfile";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { PredictionDetail } from "./components/predictionDetail/PredictionDetail.js";
import { TransactionManagement } from "./components/transactionManagement/TransactionManagement.js";
import { ContactUs } from "./components/users/ContactUs";
import { SoloPredictions } from "./components/soloPredictions/SoloPredictions.js";
import { GroupTemplates } from "./components/groupTemplates/GroupTemplates.js"
import { GroupTemplateDetail } from "./components/groupTemplates/GroupTemplateDetail.js"
import { SoloDetail } from "./components/soloDetail/index.js";
import { MultiChoicePredictionTemplates } from "./components/multiChoicePredictionTemplates/MultiChoicePredictionTemplates.js"
import { MultiChoicePredictionTemplateDetail } from "./components/multiChoicePredictionTemplates/MultiChoicePredictionTemplateDetail.js"
import BetsWon from "./components/betsWon/BetsWons.js";
import { FailedTransactions } from "./components/failedTransactions/FailedTransactions.js";
import { RedeemList } from "./common/RedeemList.js";
import { FreeGroupPredictionTemplates } from "./components/freeGroupPredictionTemplates/FreeGroupPredictionTemplates.js";
import { FreeGroupPredictionTemplateDetail } from "./components/freeGroupPredictionTemplates/FreeGroupPredictionTemplateDetail.js";
import { Whitelist } from "./components/whitelist/Whitelist.js";
import { ResultFailedTransaction } from "./components/resultFailedTransaction/ResultFailedTransaction.js";
import { SetMaximumGasFee } from "./components/gasFeeManage/SetMaximumGasFee.js";
import { PaypalOrders } from "./components/paypalOrders/PaypalOrders.js";
import { ScoresPredictionResultFailed } from "./components/scoresPredictionResultFailed/ScoresPredictionResultFailed.js";
import BenjaminNft from "./components/benjaminnftupdates/BenjaminNft.js";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [openLoad, setOpenLoad] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  useEffect(() => {
    if (getItem("admin_token")) {
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MyContext.Provider
      value={{
        affiliateRequest,
        setAffiliateRequest,
        filterType,
        setFilterType,
        openLoad,
        setOpenLoad,
        editProfile,
        setEditProfile,
      }}
    >
      <>
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <UsersList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/predictions"
            element={
              <ProtectedRoute>
                <PredictionManagement />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/maximum-gas-fee"
            element={
              <ProtectedRoute>
                <SetMaximumGasFee />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/paypal-orders"
            element={
              <ProtectedRoute>
                <PaypalOrders />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/bets-won"
            element={
              <ProtectedRoute>
                <BetsWon />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/transaction"
            element={
              <ProtectedRoute>
                <TransactionManagement />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/whitelist"
            element={
              <ProtectedRoute>
                <Whitelist />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/transaction/:address"
            element={
              <ProtectedRoute>
                <TransactionManagement />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/failed-transactions"
            element={
              <ProtectedRoute>
                <FailedTransactions />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/result_failed-transactions"
            element={
              <ProtectedRoute>
                <ResultFailedTransaction />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/scores-prediction-result-failed"
            element={
              <ProtectedRoute>
                <ScoresPredictionResultFailed />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/solo-detail/:id"
            element={
              <ProtectedRoute>
                <SoloDetail />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/prediction-detail/:id"
            element={
              <ProtectedRoute>
                <PredictionDetail />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/contact-us"
            element={
              <ProtectedRoute>
                <ContactUs />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/ChangePassword"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/Admin"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          >
            {" "}
          </Route>

          <Route
            path="/solo-predictions"
            element={
              <ProtectedRoute>
                <SoloPredictions />
              </ProtectedRoute>
            }
          >
            {" "}
          </Route>

          <Route
            path="/group-templates"
            element={
              <ProtectedRoute>
                <GroupTemplates />
              </ProtectedRoute>
            }
          >
            {" "}
          </Route>
          <Route
            path="/group-template-detail/:id"
            element={
              <ProtectedRoute>
                <GroupTemplateDetail />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/multichoice-prediction-templates"
            element={
              <ProtectedRoute>
                <MultiChoicePredictionTemplates />
              </ProtectedRoute>
            }
          >
            {" "}
          </Route>
          <Route
            path="/multichoice-prediction-template-detail/:id"
            element={
              <ProtectedRoute>
                <MultiChoicePredictionTemplateDetail />
              </ProtectedRoute>
            }
          ></Route>

          {/* free group predictions  */}
          <Route
            path="/freegroup-prediction-templates"
            element={
              <ProtectedRoute>
                <FreeGroupPredictionTemplates />
              </ProtectedRoute>
            }
          >
            {" "}
          </Route>
          <Route
            path="/freegroup-prediction-template-detail/:id"
            element={
              <ProtectedRoute>
                <FreeGroupPredictionTemplateDetail />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/redeemList"
            element={
              <ProtectedRoute>
                <RedeemList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/benjamin-NFT-updates"
            element={
              <ProtectedRoute>
                <BenjaminNft />
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}
      </>
    </MyContext.Provider>
  );
}

export default App;
